








































































































import Vue from 'vue';
import axios from 'axios';
import {
  SmartTable,
  Config,
  State,
  Column,
  Sorting,
  PagingOptions,
  Filter
} from 'rey-vue-smarttable';
import Coating from '@/models/Coating';
import ProcessingStepCoating from '@/models/ProcessingStepCoating';
import EntityType from '@/models/EntityType';
import SystemParameterService from '@/services/SystemParameterService';
import FieldDefinitionLoader from '@/services/FieldDefinitionLoader';
import AuthenticationService from '@/services/AuthenticationService';

export default Vue.extend({
  name: 'coatings',
  components: { SmartTable },
  data() {
    return {
      AuthenticationService: AuthenticationService,
      coatingTableConfig: new Config(
        (row) => row.coatingId,
        [
          new Column({
            title: this.$t('views.admin.coatings.tableHeaders.id').toString(),
            fieldName: 'coatingId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.admin.coatings.tableHeaders.number').toString(),
            fieldName: 'coatingNumber',
            fieldType: 'string',
            editable: () => AuthenticationService.isAuthenticated,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 50,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 30
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.admin.coatings.tableHeaders.description').toString(),
            fieldName: 'description',
            fieldType: 'string',
            editable: () => AuthenticationService.isAuthenticated,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 255,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 255
                }).toString()
              ]
            })
          })
        ],
        new Sorting('coatingNumber', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'Coating'),
      selectedCoating: undefined as Coating | undefined,
      processingStepCoatingTableState: new State(new Sorting('ProcessStepSortOrder', 'ascending')),
      processingStepCoatingTableConfig: undefined as Config | undefined,
      selectedProcessingStepCoating: undefined as ProcessingStepCoating | undefined,
      maxNumberOfProcessingStepCycles: 0
    };
  },
  watch: {
    async selectedCoating() {
      await this.updateProcessingSteps();
    }
  },
  async mounted() {
    this.maxNumberOfProcessingStepCycles = await SystemParameterService.getSystemParameterNumber(
      'MaxNumberOfProcessingStepCycles'
    );
  },
  methods: {
    async updateProcessingSteps() {
      this.processingStepCoatingTableConfig = new Config(
        (row) => row.ProcessingStepId,
        [
          new Column({
            title: this.$t('views.admin.coatings.tableHeadersSteps.id').toString(),
            fieldName: 'ProcessingStepId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.admin.coatings.tableHeadersSteps.coating').toString(),
            fieldName: 'CoatingId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true,
            defaultValue: this.selectedCoating?.coatingId
          }),
          new Column({
            title: this.$t('views.admin.coatings.tableHeadersSteps.stepSortOrder').toString(),
            fieldName: 'ProcessStepSortOrder',
            fieldType: 'numeric',
            description: this.$t(
              'views.admin.coatings.tableHeadersSteps.descriptions.stepSortOrder'
            ).toString(),
            editable: () => true
          })
        ],
        new Sorting('ProcessStepSortOrder', 'ascending'),
        new PagingOptions(10),
        undefined,
        undefined,
        [new Filter('CoatingId', 'numeric', 'equals', [this.getCurrentCoatingFilter()])]
      ).withEfApiActions(axios, 'ProcessingSteps');
      await FieldDefinitionLoader.loadDynamicColumnDefinitionsToSmartTableConfig(
        EntityType.ProcessingStep,
        this.processingStepCoatingTableConfig,
        true
      );
    },
    isMaxNumberOfCyclesReached(): boolean {
      // @ts-ignore
      const currentNumberOfCycles =
        // @ts-ignore
        this.$refs?.smartTableProcessingSteps?.$refs?.smartTable?.rows?.length ?? 0;
      return currentNumberOfCycles >= this.maxNumberOfProcessingStepCycles;
    },
    getCurrentCoatingFilter(): string {
      if (this.selectedCoating) {
        return this.selectedCoating.coatingId.toString();
      }

      return '0';
    },
    getIcons(currentValue: string): string[] {
      return JSON.parse(currentValue);
    },
    // eslint-disable-next-line
    isIconsArray(column: any, currentValue: string): boolean {
      if (!currentValue) {
        return false;
      }
      if (!currentValue.toString().startsWith('[')) {
        return false;
      }
      if (!currentValue.toString().endsWith(']')) {
        return false;
      }
      if (!currentValue.toString().includes('fa')) {
        return false;
      }
      try {
        JSON.parse(currentValue);
        return true;
      } catch {
        return false;
      }
    }
  }
});
