import i18n from '@/languages/i18n';

export enum EntityType {
  Article = 0,
  TrackingData = 1,
  ProcessingStep = 2,
  ZoneLog = 3,
  GeometryColorParameter = 4
}

export const EntityTypeDropdown = () => [
  {
    id: EntityType.Article,
    title: i18n.t('dropdowns.entityTypes.article').toString()
  },
  {
    id: EntityType.TrackingData,
    title: i18n.t('dropdowns.entityTypes.trackingData').toString()
  },
  {
    id: EntityType.ProcessingStep,
    title: i18n.t('dropdowns.entityTypes.processingStep').toString()
  },
  {
    id: EntityType.ZoneLog,
    title: i18n.t('dropdowns.entityTypes.zoneLog').toString()
  },
  {
    id: EntityType.GeometryColorParameter,
    title: i18n.t('dropdowns.entityTypes.geometryColorParameter').toString()
  }
];

export default EntityType;
